:root {
    --swiper-theme-color: #D2CDB5;
}

.swiper {
  z-index: 0;
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
    z-index: 0;
}

@media (hover: hover) {
    .swiper-button-prev:hover, .swiper-button-next:hover {
        transition: all 0.5s ease;
        color: white;
    }
}

@media only screen and (max-width: 480px) {
    .swiper-button-next, .swiper-button-prev {
        visibility: hidden;
    }
}